import * as React from 'react'
import Layout from '../../layout'
import GroupsPage from '../../pagesComponents/groups/index'

const Groups = () => {
	return (
		<Layout title="Groups">
			<GroupsPage />
		</Layout>
	)
}

export default Groups
